<template>
  <div id="form-crud-user">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    <form @submit.prevent="initSubmit">

      {{ /* Informations globales */ }}
      <div class="card border-0 shadow p-3">
        <h4 class="">{{ $t('common.globalInfo') }}</h4>
        <hr/>

        <div class="col-12 col-md-6">

          <div class="form-group">
            <label>{{ $t('pages.user.prospect') }}</label>

            <div class="form-check">
              <input class="form-check-input" type="radio" id="is-prospect-yes" v-model="reseller.isProspect"
                     :checked="reseller.isProspect === true" v-bind:value="true">
              <label class="form-check-label" for="is-prospect-yes">
                {{ $t('common.yes') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" id="is-prospect-no" v-model="reseller.isProspect"
                     :checked="reseller.isProspect === false" v-bind:value="false">
              <label class="form-check-label" for="is-prospect-no">
                {{ $t('common.no') }}
              </label>
            </div>
          </div>
          <div class="form-group" v-if="this.$store.getters.userIsAdmin">
            <label for="company">{{ $t('pages.reseller.form.apiRelease') }}</label>
            <input class="form-control" id="api-version" v-model="reseller.apiVersion" type="text">
            <div v-if="errorsForm.apiVersion">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.apiVersion.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="company">{{ $t('common.company') }} *</label>
            <input class="form-control" id="company" v-model="reseller.company" type="text">
            <div v-if="errorsForm.company">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.company.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="firstname">{{ $t('common.firstname') }} *</label>
            <input class="form-control" id="firstname" v-model="reseller.firstname" type="text">
            <div v-if="errorsForm.firstname">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.firstname.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="lastname">{{ $t('common.lastname') }} *</label>
            <input class="form-control" id="lastname" v-model="reseller.lastname" type="text">
            <div v-if="errorsForm.lastname">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.lastname.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="phone">{{ $t('common.phone') }}</label>
            <input class="form-control" id="phone" v-model="reseller.phone" type="number">
            <div v-if="errorsForm.password">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.phone.errors" :key="index">{{
                  error
                }}</p>
            </div>
          </div>

          <div class="form-group" v-if="this.$store.getters.userIsReseller">
            <label>{{ $t('pages.reseller.form.yourLogo') }} :</label>
            <div>
              <input class="btn btn-dark" type="file" accept="image/png, image/jpg, image/jpeg, image/bmp"
                     @change="handleFileUpload($event)">
            </div>
            <div class="mt-2">
              <img :src="reseller.picture"/>
            </div>
          </div>
        </div>
      </div>

      {{ /* Informations de connexion */ }}
      <div class="card border-0 shadow p-3" v-if="this.$store.getters.userIsAdmin">
        <h4 class="">{{ $t('pages.reseller.form.loginInfo') }}</h4>
        <hr/>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="username">{{ $t('pages.reseller.tableList.mailReseller') }} *</label>
            <input class="form-control" id="username" v-model="reseller.username" type="email">
            <div v-if="errorsForm.username">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.username.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="password">{{ $t('common.password') }}</label>
            <input class="form-control" id="password" v-model="reseller.password" type="password">
            <div v-if="errorsForm.password">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.password.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>
        </div>
      </div>

      {{ /* Données API */ }}
      <div class="card border-0 shadow p-3" v-if="this.$store.getters.userIsAdmin">
        <h4>{{ $t('pages.reseller.form.apiData') }}</h4>
        <hr/>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="thetis-key">Thetis key *</label>
            <input class="form-control" id="thetis-key" v-model="reseller.thetis_key" type="text">
            <div v-if="errorsForm.thetis_key">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.thetis_key.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>
        </div>
      </div>

      {{ /* Informations de vente */ }}
      <div class="card border-0 shadow p-3" v-if="this.$store.getters.userIsAdmin">
        <h4>{{ $t('pages.reseller.form.salesInfo') }}</h4>
        <hr/>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="vat">{{ $t('pages.reseller.form.vat') }}</label>
            <input class="form-control" id="vat" v-model="reseller.vat" type="number" step="0.01">
            <div v-if="errorsForm.vat">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.vat.errors" :key="index">{{
                  error
                }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="vat-number">{{ $t('pages.reseller.form.vatNumber') }}</label>
            <input class="form-control" id="vat-number" v-model="reseller.vatNumber" type="number" step="0.01">
            <div v-if="errorsForm.vatNumber">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.vatNumber.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="siret-number">{{ $t('pages.reseller.form.registerNumber') }}</label>
            <input class="form-control" id="siret-number" v-model="reseller.siret" type="number" step="0.01">
            <div v-if="errorsForm.siret">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.siret.errors" :key="index">{{
                  error
                }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="payment-deadline-date">{{ $t('pages.reseller.form.paymentDeadline') }}</label>
            <Datepicker input-class="w-100" id="payment-deadline-date" v-model="reseller.paymentDeadline"
                        :format="formatDate"></Datepicker>
            <div v-if="errorsForm.paymentDeadline">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.paymentDeadline.errors"
                 :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label>{{ $t('pages.reseller.form.paymentMethod') }}</label>
            <div class="ml-5" v-for="(mode, index) in form.paymentMode" :key="index">
              <input
                  class="form-check-input"
                  type="radio"
                  :id="'payment-mode' + index"
                  :checked="reseller.paymentMode === mode.tag"
                  :value="mode.tag"
                  v-model="reseller.paymentMode"
                  @change="radioPaymentMode($event, mode.tag)"
              />

              <label :for="'payment-mode' + index" class="ml-2">{{ mode.name }}</label>
            </div>
            <div v-if="errorsForm.paymentMode">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.paymentMode.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label>{{ $t('pages.reseller.tableList.discount') }}</label>

            <input class="form-control" id="discount" v-model="reseller.discount" type="text">
          </div>

          <div class="form-group">
            <label for="select-type">Type *</label>

            <select id="select-type" class="form-control" v-model="selectType" @input="changeType">
              <option v-for="(type, index) in form.type" :key="index" :value="type.tag">
                {{ type.name }}
              </option>
            </select>
            <div v-if="errorsForm.type">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.type.errors" :key="index">{{
                  error
                }}</p>
            </div>
          </div>

          <div class="form-group" v-if="this.$store.getters.countriesList">
            <label for="select-country">{{ $t('pages.reseller.form.defaultResellerCountryForQuote') }}</label>
            <select id="select-country" class="form-control" v-model="reseller.country">
              <option :value="null" :selected="!reseller.country">{{ $t('common.none') }}</option>
              <option :value="reseller.country" v-if="currentUserCountryName" :selected="reseller.country">
                {{ currentUserCountryName }}
              </option>
              <option v-for="(country, index) in this.$store.getters.countriesList" :key="index" :value="country.id">
                {{ country.name }}
              </option>
            </select>
            <div v-if="errorsForm.type">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.type.errors" :key="index">{{
                  error
                }}</p>
            </div>
          </div>
          <div class="form-group">
            <label for="select-marge-type">Marge calcul *</label>

            <select id="select-marge-type" class="form-control" v-model="selectMargeType" @input="changeMargeType">
              <option v-for="(margeType, index) in form.margeType" :key="index" :value="margeType.tag">
                {{ margeType.name }}
              </option>
            </select>
            <div v-if="errorsForm.margeType">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.margeType.errors" :key="index">{{
                  error
                }}</p>
            </div>
          </div>
        </div>
      </div>

      {{ /* Informations livraison */ }}
      <div class="card border-0 shadow p-3" v-if="this.$store.getters.userIsAdmin">
        <h4>{{ $t('pages.reseller.form.deliveryInfo') }}</h4>
        <hr/>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="delivery-firstname">{{ $t('common.firstname') }}</label>
            <input class="form-control" id="delivery-firstname" v-model="reseller.deliveryFirstname" type="text">
            <div v-if="errorsForm.deliveryFirstname">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.deliveryFirstname.errors"
                 :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="delivery-lastname">{{ $t('common.lastname') }}</label>
            <input class="form-control" id="delivery-lastname" v-model="reseller.deliveryLastname" type="text">
            <div v-if="errorsForm.deliveryLastname">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.deliveryLastname.errors"
                 :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="delivery-address">{{ $t('common.address') }}</label>
            <input class="form-control" id="delivery-address" v-model="reseller.deliveryAddress" type="text">
            <div v-if="errorsForm.deliveryAddress">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.deliveryAddress.errors"
                 :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="delivery-address2">{{ $t('common.secondAddress') }}</label>
            <input class="form-control" id="delivery-address2" v-model="reseller.deliveryAddress2" type="text">
            <div v-if="errorsForm.deliveryAddress2">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.deliveryAddress2.errors"
                 :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="delivery-city">{{ $t('common.city') }}</label>
            <input class="form-control" id="delivery-city" v-model="reseller.deliveryCity" type="text">
            <div v-if="errorsForm.deliveryCity">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.deliveryCity.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="delivery-zip-code">{{ $t('common.zipCode') }}</label>
            <input class="form-control" id="delivery-zip-code" v-model="reseller.deliveryZipCode" type="text">
            <div v-if="errorsForm.deliveryZipCode">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.deliveryZipCode.errors"
                 :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="delivery-country">{{ $t('common.country') }}</label>
            <input class="form-control" id="delivery-country" v-model="reseller.deliveryCountry" type="text">
            <div v-if="errorsForm.deliveryCountry">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.deliveryCountry.errors"
                 :key="index">{{ error }}</p>
            </div>
          </div>
        </div>
      </div>

      {{ /* Informations facturation */ }}
      <div class="card border-0 shadow p-3" v-if="this.$store.getters.userIsAdmin">
        <h4>{{ $t('pages.reseller.form.billingInfo') }}</h4>
        <hr/>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="billing-firstname">{{ $t('common.firstname') }}</label>
            <input class="form-control" id="billing-firstname" v-model="reseller.billingFirstname" type="text">
            <div v-if="errorsForm.billingFirstname">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.billingFirstname.errors"
                 :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="billing-lastname">{{ $t('common.lastname') }}</label>
            <input class="form-control" id="billing-lastname" v-model="reseller.billingLastname" type="text">
            <div v-if="errorsForm.billingLastname">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.billingLastname.errors"
                 :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="billing-address">{{ $t('common.address') }}</label>
            <input class="form-control" id="billing-address" v-model="reseller.billingAddress" type="text">
            <div v-if="errorsForm.billingAddress">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.billingAddress.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="billing-address2">{{ $t('common.secondAddress') }}</label>
            <input class="form-control" id="billing-address2" v-model="reseller.billingAddress2" type="text">
            <div v-if="errorsForm.billingAddress2">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.billingAddress2.errors"
                 :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="billing-city">{{ $t('common.city') }}</label>
            <input class="form-control" id="billing-city" v-model="reseller.billingCity" type="text">
            <div v-if="errorsForm.billingCity">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.billingCity.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="billing-zip-code">{{ $t('common.zipCode') }}</label>
            <input class="form-control" id="billing-zip-code" v-model="reseller.billingZipCode" type="text">
            <div v-if="errorsForm.billingZipCode">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.billingZipCode.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="billing-country">{{ $t('common.country') }}</label>
            <input class="form-control" id="billing-country" v-model="reseller.billingCountry" type="text">
            <div v-if="errorsForm.billingCountry">
              <p class="form-error text-danger" v-for="(error, index) in errorsForm.billingCountry.errors" :key="index">
                {{ error }}</p>
            </div>
          </div>
        </div>
      </div>

      {{ /* SCENARIO */ }}
      <div class="card border-0 shadow p-3" v-if="this.$store.getters.userIsAdmin">
        <h4>{{ $t('common.singleScenario') }}</h4>
        <hr/>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label>{{ $t('pages.reseller.form.assignScenario') }}</label>

            <div class="ml-5" v-for="(scenario, index) in ScenarioModule.scenarios" :key="index">
              <input
                  class="form-check-input"
                  type="checkbox"
                  :id="'scenario-' + index"
                  :checked="form.listFormScenarios.includes(scenario.id)"
                  :value="scenario.id"
                  @change="checkboxScenario($event, scenario.id)"
              />

              <label :for="'mandrel-' + index" class="ml-2">{{ scenario.name }}</label>
            </div>
          </div>
        </div>
      </div>

      {{ /* Config */ }}
      <div class="card border-0 shadow p-3" v-if="this.$store.getters.userIsAdmin">
        <h4>Configuration</h4>
        <hr/>

        <div class="col-12 col-md-6">
          <div class="form-group">
            <label>{{ $t('pages.user.activateUser') }}</label>

            <div class="form-check">
              <input class="form-check-input" type="radio" id="is-active-yes" v-model="reseller.isActive"
                     :checked="reseller.isActive === true" v-bind:value="true">
              <label class="form-check-label" for="is-active-yes">
                {{ $t('common.yes') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" id="is-active-no" v-model="reseller.isActive"
                     :checked="reseller.isActive === false" v-bind:value="false">
              <label class="form-check-label" for="is-active-no">
                {{ $t('common.no') }}
              </label>
            </div>
          </div>
          <div class="form-group">
            <label>{{ $t('pages.user.internalReferenceRequired') }}</label>

            <div class="form-check">
              <input class="form-check-input" type="radio" id="is-internal-ref-yes" v-model="reseller.internalRefRequired"
                     :checked="reseller.internalRefRequired === true" v-bind:value="true">
              <label class="form-check-label" for="is-internal-ref-yes">
                {{ $t('common.yes') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" id="is-internal-ref-no" v-model="reseller.internalRefRequired"
                     :checked="reseller.internalRefRequired === false" v-bind:value="false">
              <label class="form-check-label" for="is-internal-ref-no">
                {{ $t('common.no') }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="card border-0 shadow p-3" v-if="this.reseller.mailConfig">
        <h4>Emails</h4>
        <hr/>

        <div class="ml-5" v-for="(mail, index) in reseller.mailConfig" :key="index">

          <div class="form-group">
            <label>{{ $t('common.email.' + index) }}</label>

            <div class="form-check">
              <input class="form-check-input" type="radio" v-model="reseller.mailConfig[index]" :checked="mail === true"
                     v-bind:value="true">
              <label class="form-check-label" :for="'mail-true-' + index">
                {{ $t('common.yes') }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" v-model="reseller.mailConfig[index]" :checked="mail === false"
                     v-bind:value="false">
              <label class="form-check-label" :for="'mail-false-' + index">
                {{ $t('common.no') }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <input type="submit" class="btn mt-4 ml-3 mb-2 btn-success" :value="valueButtonSubmit"/>
    </form>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: {
    reseller: Object,
    id: Number,
    selectedType: String,
    selectedMargeType: String,
    actionForm: String
  },
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      formatDate: 'dd/MM/yyyy',
      errorsForm: [],
      selectType: this.selectedType,
      selectMargeType: this.selectedMargeType,
      form: {
        listFormScenarios: [],
        paymentMode: [
          {name: 'None', tag: 'none'},
          {name: this.$t('pages.reseller.form.bankCard'), tag: 'bank-card'},
          {name: this.$t('pages.reseller.form.species'), tag: 'cash'}
        ],
        type: [
          {name: 'Api', tag: 'api'},
          {name: 'Web', tag: 'web'}
        ],
        margeType: [
          {name: 'Markup (basic)', tag: 'markup'},
          {name: 'Gross margin', tag: 'gross_margin'},
        ]
      }
    }
  },
  watch: {
    selectedType: function () {
      this.selectType = this.selectedType
    },
    selectedMargeType: function () {
      this.selectMargeType = this.selectedMargeType
    }
  },
  created: function () {
    // Scenario list
    this.$store.dispatch('fetchScenarios', {
      perPage: null,
      page: null,
      order: null
    })

    this.$store.dispatch('fetchCountries')

    // Affect checked scenario
    this.form.listFormScenarios = this.getListIdScenarios
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- SELECT ------------------*/
    changeType() {
      this.$emit('select', this.selectedType)
    },
    changeMargeType() {
      this.$emit('select', this.selectedMargeType)
    },
    /*----------------- FILES METHOD ------------------*/
    handleFileUpload($event) {
      let files = $event.target.files || $event.dataTransfer.files
      this.handleFileToBase64(files[0])
    },
    /*----------------- CHECKBOX ------------------*/
    checkboxScenario(event, scenarioId) {
      if (event.target.checked) {
        this.form.listFormScenarios.push(scenarioId)
      } else {
        let indexScenario = this.form.listFormScenarios.findIndex(array => array === scenarioId)
        if (indexScenario > -1) {
          this.form.listFormScenarios.splice(indexScenario, 1)
        }
      }
    },
    /*----------------- SUBMIT ------------------*/
    initSubmit() {
      // Handle Type
      this.reseller.type = this.selectType
      this.reseller.margeType = this.selectMargeType
      // Handle scenario list
      this.reseller.scenarios = this.form.listFormScenarios

      // If reseller (create or update)
      if (this.actionForm === 'edit') {
        this.editReseller()
      } else {
        this.createReseller()
      }
    },
    /**
     * Create reseller (submit form)
     */
    createReseller() {
      this.showLoader()

      this.$store.dispatch('createReseller', this.reseller)
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Update reseller (submit form)
     */
    editReseller() {
      this.showLoader()

      this.$store.dispatch('updateReseller', {id: this.reseller.id, reseller: this.reseller})
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Redirect to reseller-index after submit
     * Refresh ErrorsForm
     */
    redirectAndRefresh() {
      // Redirect
      if (this.$store.getters.userIsAdmin) {
        this.$router.push({name: 'reseller-index'})
      }

      // Refresh errors form
      this.errorsForm = []
    },
    /*----------------- PRIVATE METHODS ------------------*/
    handleFileToBase64(file) {
      let that = this

      var reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = function () {
        that.reseller.picture = reader.result
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    }
  },
  computed: {
    currentUserCountryName() {
      if (this.reseller && this.reseller.country && this.reseller.country.id && this.$store.getters.countriesList) {
        const country = this.$store.getters.countriesList.find(country => country.id === this.reseller.country.id)
        return country.name
      }
      return null
    },
    /**
     * Display value for button submit
     * @returns {string}
     */
    valueButtonSubmit() {
      let value = ''
      if (this.$store.getters.userIsAdmin) {
        if (this.actionForm === 'edit') {
          value = this.$t('pages.reseller.editReseller')
        } else {
          value = this.$t('pages.reseller.createReseller')
        }
      } else {
        value = this.$t('common.validate')
      }

      return value
    },
    /**
     * Create list id of scenario
     * @returns {[]}
     */
    getListIdScenarios() {
      let scenariosId = []

      if (this.reseller.scenarios && this.reseller.scenarios.length > 0) {
        this.reseller.scenarios.forEach(function (scenario) {
          scenariosId.push(scenario.id)
        })
      } else {
        this.ScenarioModule.scenarios.forEach((scenario) => {
          scenariosId.push(scenario.id)
        })
      }

      return scenariosId
    },
    ...mapState(['UserModule']),
    ...mapState(['ScenarioModule']),
    ...mapState(['CountryModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    Datepicker: () => import('vuejs-datepicker')
  }
}
</script>

<style scoped>

</style>
